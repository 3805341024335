import LoginForm from '../../components/AdminPageForms/LoginForm';

const AdminLogin = () => {
  return (
    <div>
      <LoginForm />
    </div>
  );
};

export default AdminLogin;
